import * as React from "react"
import { Link } from 'gatsby'
import "./style.css"
import Layout from '../components/layout.js'
import Header from '../components/header.js'
import { StaticImage } from "gatsby-plugin-image"
import { homeRow, homeRowImg, homeRowContent, homeLink, homeLinkArrow, homeDivider } from '../components/homepage.module.css'
import { headerImage } from '../components/header.module.css'
import chipImg from '../images/header-emote.png'

// markup
const HomePage = () => {
  return (
    <Layout>
      <Header
        image={<img
          src={chipImg}
          className={headerImage}
          alt="Chipstarr878's head"
        />}
      />
      <section className={homeRow}>
        <StaticImage
          src="../images/chip-logo-stride.png"
          alt="An illustration of Chipstarr878's mascot striding forward in triumph"
          className={homeRowImg}
        />
        <div className={homeDivider}></div>
        <div className={homeRowContent}>
          <h2>Hey, I'm Chipstarr. I'll be here playing games and having a good time. Feel free to drop by and have a chill time with the lads and ladies.</h2>
          <p>Knowing yourself is the beginning of all wisdom. It is the mark of an educated mind to be able to entertain a thought without accepting it. Educating the mind without educating the heart is no education at all. Hope is a waking dream. Quality is an act, not a habit.</p>
          <Link paintDrip className={homeLink} to="/links">All my links <StaticImage src="../images/arrow_right_alt-24px.png" className={homeLinkArrow} /></Link>
        </div>
      </section>

    </Layout>
  )
}

export default HomePage
